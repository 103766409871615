<template>
  <div>
    <IconSprite />
    <WishlistSidebar v-if="isWishlistSidebarOpen" />
    <FiltersSidebar v-if="isFilterSidebarOpen" />
    <CartSidebar v-if="isCartSidebarOpen" />
    <LoginAndRegisterModal v-if="loginAndRegisterModalOpen" />
    <ChangeCountryModal v-if="showChangeCountry" />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <HeaderTopBar />
    <AppHeader />
    <div id="layout">
      <nuxt :key="route.fullPath" />
    </div>
    <LazyHydrate when-visible>
      <AppFooter />
    </LazyHydrate>
    <WhatsAppChatButton />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
  useMeta,
  useContext,
} from '@nuxtjs/composition-api';
import AppHeader from '~/components/AppHeader.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginAndRegisterModal from '~/components/LoginAndRegisterModal.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';
import {
  useTranslation,
  useUser,
  useUiState,
  useCart,
  useWishlist,
  useGtm,
} from '~/composables/';
import Notification from '~/components/Notification.vue';
import { WishlistSidebar, FiltersSidebar } from '~/components/Sidebars';
import { ChangeCountryModal } from '~/components/Modals';
import { HeaderTopBar } from '~/components/HeaderComponents';
import WhatsAppChatButton from '~/components/General/WhatsAppChatButton.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    WhatsAppChatButton,
    LazyHydrate,
    AppHeader,
    WishlistSidebar,
    FiltersSidebar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar,
    LoginAndRegisterModal,
    Notification,
    IconSprite: () =>
      import(/* webpackPrefetch: true */ '~/components/General/IconSprite.vue'),
    HeaderTopBar,
    ChangeCountryModal,
  },
  head: {},
  setup() {
    const route = useRoute();
    const {
      isAuthenticated,
      user,
      load: loadUser,
      loading: loadingUser,
      setAuthenticatedState,
    } = useUser();
    const {
      app: { $cookies },
    } = useContext();
    const { cart, load: loadCart } = useCart();
    const flag = ref(false);
    const { loadAllTranslations } = useTranslation('translations');
    const cookiebot = process && process.browser && window && window.Cookiebot;
    const hasAlreadyDeclared = !!$cookies.get('CookieConsent');
    const { addGtmListener } = useGtm();

    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      loginAndRegisterModalOpen,
      isFilterSidebarOpen,
      lockBodyScroll,
      showChangeCountry,
    } = useUiState();
    const { loadWishlistItemsCount } = useWishlist();

    const { loadConfiguration } = useGeminiConfiguration();

    onMounted(async () => {
      if (cookiebot && !hasAlreadyDeclared) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
      addGtmListener();
      if (!user.value && !loadingUser.value) {
        await loadUser();
      }
      if (!cart.value) {
        await loadCart();
      }
      await loadWishlistItemsCount();
    });

    useFetch(async () => {
      setAuthenticatedState();
      await loadAllTranslations();
      loadConfiguration();
    });

    useMeta(() => ({
      htmlAttrs: {
        lang: route?.value?.path?.split('/')?.[1] === 'it' ? 'it' : 'en',
      },
      bodyAttrs: {
        class: `${lockBodyScroll.value ? 'lock-body-scroll' : ''}`,
      },
    }));

    return {
      ...useTranslation('translations'),
      route,
      isAuthenticated,
      flag,
      user,
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      loginAndRegisterModalOpen,
      isFilterSidebarOpen,
      cart,
      showChangeCountry,
    };
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
@import '@storefront-ui/shared/styles/helpers/_colors.scss';
@import '../assets/style/variables.scss';
@import '../assets/style/default.scss';
@import '@/static/css/formspree.css';
</style>
